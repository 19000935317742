<template>
  <el-drawer
    title="我是标题"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    width="80%"
  >
    <el-transfer
      v-model="dataList"
      @change="handleChange"
      filterable
      :titles="['选择角色', '已经配置的角色']"
      :button-texts="['到左边', '到右边']"
      :data="data"
      :props="{
      key: 'roleCode',
      label: 'roleName'
    }"
    ></el-transfer>
    <el-button type="primary" @click="addFormButton()">确 定</el-button>
  </el-drawer>
</template>
<script>
import { addProductRoeApi } from "@/api/system/product/product.js";
import { getRoleListByUserApi,getBindRoleByProductIdApi,getBindRoleListByUserApi } from "@/api/system/role/role.js";
import { addRoleAndUserApi } from "@/api/system/user/user.js";
export default {
  data() {
    return {
      drawer: false,
      direction: "rtl",
      //未选择这边
      data: [],
      dataList: [],
      commodityCode: "",
      userSign: ""
    };
  },
  methods: {
    show(row) {
      this.drawer = true;
      //   this.commodityCode = commodityCode;
      this.userSign = row.userSign;
      this.initRole(row.userSign);
      //   this.listroleButton();
        this.getBindRoleByProductIdButton();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.clearn();
          done();
        })
        .catch(_ => {});
    },
    clearn() {
      (this.dataList = []), (this.data = []), (this.drawer = false);
      this.$parent.commonUserListButton();
    },
    handleChange(value, direction, movedKeys) {
      console.log("1" + value, movedKeys);
      console.log("2" + direction);
      console.log("3" + this.dataList);
    },
    addFormButton() {
      var dataButList = [];
      for (var i = 0; i < this.dataList.length; i++) {
        var role = {
          roleCode: this.dataList[i]
        };
          dataButList.push(role);
      }
      //传入的数据
      var addRoleFrom = {
        userSign: this.userSign,
        roleUserRespInfoVOList: dataButList
      };
      addRoleAndUserApi(addRoleFrom).then(res => {
        this.clearn();
      });
    },
    listroleButton() {
      listroleApi().then(res => {
        this.data = res.result.records;
      });
    },
    getBindRoleByProductIdButton() {
      getBindRoleListByUserApi({ userSign: this.userSign }).then(
        res => {
          var roleCodeList = res.result.map(function(vo) {
            return vo.roleCode;
          });
          this.dataList = roleCodeList;
          console.log( this.dataList)
        }
      );
    },
    initRole(userSign) {
      getRoleListByUserApi({ userSign: userSign }).then(res => {
        this.data = res.result;
      });
    }
  }
};
</script>
<style scoped>
</style>