import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/common/commonRole'


const listroleApi =(params)=>getAction(baseURL+"/list",params);

const dataListApi=(params)=>getAction(baseURL+"/dataList",params);

const addroleApi=(params)=>postAction(baseURL+"/add",params);

const editroleApi=(params)=>putAction(baseURL+"/edit",params);

const deleteroleByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectroleByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

const getBindRoleByProductIdApi=(params)=>getAction(baseURL+'/getBindRoleByProductId',params);

const addRoleMenuApi=(params)=>postAction(baseURL+'/addRoleMenu',params);


const getRoleListByUserApi=(params)=>postAction(baseURL+'/getRoleListByUser',params);
const getBindRoleListByUserApi=(params)=>postAction(baseURL+'/getBindRoleListByUser',params);
// const addRoleMenuApi=(params/)=>postAction(baseURL+'/addRoleMenu',params);

export{
    selectroleByIdApi,
    deleteroleByIdApi,
    editroleApi,
    addroleApi,
    listroleApi,
    getBindRoleByProductIdApi,
    addRoleMenuApi,
    dataListApi,
    getRoleListByUserApi,
    getBindRoleListByUserApi
}
