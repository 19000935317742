import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request';
const baseURL = 'xun/common/commonProduct'


const listproductApi =(params)=>getAction(baseURL+"/list",params);

const listDataProductApi =(params)=>getAction(baseURL+"/dataList",params);

const addproductApi=(params)=>postAction(baseURL+"/add",params);

const editproductApi=(params)=>putAction(baseURL+"/edit",params);

const deleteproductByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectproductByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

const listByUser = (params) => getAction(baseURL + "/listByUser", params);

const addProductRoeApi = (params) => postAction(baseURL + "/addProductRoe", params);

const getRoleByUserApi = (params) => getAction(baseURL + "/getRoleByUser", params);

const getExperienceDataListApi =(params)=>getAction(baseURL+"/getExperienceDataList",params);

export{
    selectproductByIdApi,
    deleteproductByIdApi,
    editproductApi,
    addproductApi,
    listproductApi,
    listByUser,
    addProductRoeApi,
    listDataProductApi,
    getRoleByUserApi,
    getExperienceDataListApi
}
