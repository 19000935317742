import { getAction, getByIdAction, postAction, putAction, deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/common/commonUser'

const getNoBindTenantsUserApi = (params) => getAction(baseURL + "/getNoBindTenantsUser", params);

const listuserApi = (params) => getAction(baseURL + "/list", params);


const userListApi = (params) => getAction(baseURL + "/userList", params);
const adduserApi = (params) => postAction(baseURL + "/add", params);

const edituserApi = (params) => putAction(baseURL + "/edit", params);

const deleteuserByIdApi = (params) => deleteByIdAction(baseURL + '/deleteById', params);

const selectuserByIdApi = (params) => getByIdAction(baseURL + '/selectById', params);

const addRoleAndUserApi = (params) => postAction(baseURL + "/addRoleAndUser", params);

export {
    selectuserByIdApi,
    deleteuserByIdApi,
    edituserApi,
    adduserApi,
    listuserApi,
    getNoBindTenantsUserApi,
    addRoleAndUserApi,
    userListApi
}
